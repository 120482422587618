/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var map = null;
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('.header__menubutton').click(function(e) {
          e.preventDefault();
          $('.header__navigation').fadeToggle();
          $(this).toggleClass('is-active');
        })

        //Check to see if the window is top if not then display button
        $(window).scroll(function(){
            if ($(this).scrollTop() > 500) {
                $('.footer__scrolltop').fadeIn();
            } else {
                $('.footer__scrolltop').fadeOut();
            }
        });

        //Click event to scroll to top
        $('.footer__scrolltop').click(function(e){
            e.preventDefault();
            $('html, body').animate({scrollTop : 0},800);
            return false;
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(document).ready(function(){

          $('.footer__maps-map').each(function(){
            // create map
            Sage['maps'].new_map($(this));
          });

          $('.footer__maps-control').click(function(e){
            e.preventDefault();
            // create map
            $('.footer__overlay').fadeToggle();
            $(this).closest('.footer').toggleClass('show_maps');
          });

        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        if ( $('.owl-carousel').length ) {
          $('.owl-carousel').owlCarousel({
            items: 1,
            nav: false,
            margin: 10,
            autoplay:true,
            autoplayTimeout:3500,
            autoplaySpeed: 500,
            autoplayHoverPause:true,
            loop: false,
            controlsClass: 'slider__controls',
            dotClass: 'slider__nav-dot',
            dotsClass: 'slider__nav-dots'
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Footer maps
    'maps': {
      add_marker: function( $marker, map ) {

        // var
        var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

        // create marker
        var marker = new google.maps.Marker({
          position  : latlng,
          map     : map
        });

        // add to array
        map.markers.push( marker );

        // if marker contains HTML, add it to an infoWindow
        if( $marker.html() ) {
          // create info window
          var infowindow = new google.maps.InfoWindow({
            content   : $marker.html()
          });

          // show info window when marker is clicked
          google.maps.event.addListener(marker, 'click', function() {
            infowindow.open( map, marker );
          });

          google.maps.event.addDomListener(window, 'resize', function() {
            Sage['maps'].center_map(map);
          });
        }
      },
      center_map: function( map ) {

        // vars
        var bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each( map.markers, function( i, marker ){
          var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
          bounds.extend( latlng );
        });

        // only 1 marker?
        if( map.markers.length == 1 ) {
          // set center of map
            map.setCenter( bounds.getCenter() );
            map.setZoom( 12 );
        } else {
          // fit to bounds
          map.fitBounds( bounds );
        }

      },
      new_map: function ($el) {
          
        // var
        var $markers = $el.find('.marker');
        
        // vars
        var args = {
          zoom    : 12,
          scrollwheel: false,
          draggable: false,
          center    : new google.maps.LatLng(0, 0),
          mapTypeId : google.maps.MapTypeId.ROADMAP
        };
        
        // create map           
        var map = new google.maps.Map( $el[0], args);
        
        // add a markers reference
        map.markers = [];
        
        // add markers
        $markers.each(function(){
            Sage['maps'].add_marker($(this), map);
        });
        
        // center map
        Sage['maps'].center_map(map);
        
        // return
        return map;
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
